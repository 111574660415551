import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, navigate } from "gatsby";
import axios from "axios";

import {
  Input,
  Button,
  AlertModal,
  Checkbox,
  PasswordValidation,
} from "../components/common";

import Auth from "../components/auth";

import { useAuth } from "../hooks/useAuth";
import { apiURL } from "../helpers";

import "./create-account.css";

function CreateAccountPage({ location }) {
  const [user, setUser] = useState({
    email: "",
    token: "",
    firstName: "",
    password: "",
    agree: false,
    success: false,
    error: false,
    message: "",
    content: "",
    loading: false,
    finalRegistration: false,
    validation: {
      email: true,
      firstName: true,
      password: true,
    },
  });
  useEffect(() => {
    if (typeof window !== "undefined") {
      const search = location.search;
      const query = new URLSearchParams(search);
      const [email, token] = [query.get("email"), query.get("token")];
      if ((email, token)) {
        verifyToken(email, token);
      }
    }
  }, []);
  const verifyToken = async (email, token) => {
    setUser({ ...user, email, token, loading: true });

    const { data } = await axios.get(
      apiURL + `/registrations?email=${email}&token=${token}`
    );
    let finalRegistration = false;

    if (data.success && data?.code === "register_request_allowed") {
      finalRegistration = true;
    }
    setUser({ ...user, email, token, loading: false, finalRegistration });
  };
  const { isAuthenticated } = useAuth();
  const handleSignUp = async (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!user?.finalRegistration) {
      const { data } = await axios.post(apiURL + "/registrations", {
        email: user.email,
      });
      switch (data.code) {
        case "already_registered":
          setUser({
            ...user,
            success: true,
            error: false,
            message: "You Already Have An Account",
            content:
              "Hi Zonayed, it seems you already have an account with us. Please go to the login page to access your purchases. If you forgot your password, you can reset your password here.",
          });
          break;
        case "register_not_allowed":
          setUser({
            ...user,
            success: false,
            error: true,
            message: "You Haven’t Purchased Anything",
            content:
              "Sorry, we haven’t found any purchase made through this email. Browse our proudcts, and purchase atleast one to create a new account. Otherwise, please check the email typo or contact us.",
          });
          break;
        case "register_request_accepted":
          return navigate("/confirm-to-create-account", {
            state: {
              email: user.email,
            },
          });
      }
    } else {
      const { data } = await axios.post(apiURL + "/registrations", {
        email: user.email,
        firstName: user.firstName,
        token: user.token,
        password: user.password,
      });

      switch (data.code) {
        case "registered_success":
          return navigate("/dashboard");
        case "not_registered":
          return navigate("/signup");
        case "invalid_register_request":
          // show alert
          return navigate("/signup");
        default:
          return navigate("/signup");
      }
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);
  const onValidate = (name, status) => {
    setUser({ ...user, validation: { ...user.validation, [name]: status } });
  };
  return (
    <>
      <AlertModal
        type={user.error ? "error" : "success"}
        show={user.error || user.success}
        title={user.message}
        content={user.content}
        onClose={() =>
          setUser({
            ...user,
            succes: false,
            error: false,
            message: false,
            content: false,
          })
        }
      />
      <Auth
        title="Create Account"
        customNav={
          !user?.finalRegistration ? (
            <div className="font-normal text-lg leading-8 tracking-tightsy text-ui-gray-plus-4 hidden md:block">
              Already have an account?{" "}
              <Link className="text-brand-primary" to="/login">
                Login!
              </Link>
            </div>
          ) : (
            <></>
          )
        }
        secondaryForm={user?.finalRegistration}
        headingTitle={
          !user?.finalRegistration
            ? "Purchased Our Product"
            : `Hi, ${String(user?.email).replace(/@.*/g, "")}`
        }
        headingDesc={
          !user?.finalRegistration
            ? "But don’t have an account?"
            : "Email confirmed, create your account"
        }
        mobileNote={
          !user?.finalRegistration && (
            <>
              Already have an account? <Link to="/login">Login!</Link>
            </>
          )
        }
      >
        {user?.finalRegistration ? (
          <>
            <Input
              disabled={true}
              pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
              value={user.email}
              onChange={(email) => setUser({ ...user, email })}
              name="email"
              label="Email address"
              placeholder="email@example.com"
              onValidate={onValidate}
              valid={true}
              note="Email is confirmed!"
            />
            <Input
              minLength={3}
              maxLength={32}
              value={user.firstName}
              onChange={(firstName) => setUser({ ...user, firstName })}
              name="firstName"
              label="First name, or what do we call you?"
              placeholder="John"
              onValidate={onValidate}
            />
            <Input
              type="password"
              value={user.password}
              onChange={(password) => setUser({ ...user, password })}
              name="password"
              label="Type a strong password"
              placeholder="Password"
              onValidate={onValidate}
              pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
              tooltip={
                <PasswordValidation
                  name={user.firstName}
                  password={user.password}
                  email={user.email}
                />
              }
            />
            <Checkbox
              title={<>I agree to Vackground’s terms & privacy policy</>}
              name="agree"
              value={user.agree}
              onChange={() => setUser({ ...user, agree: !user.agree })}
            />
            <Button
              hasMinWidth={false}
              text="Create Account & Login"
              onClick={handleSignUp}
              icon="vg-log-in"
              disabled={
                !user.email ||
                !user.firstName ||
                !user.password ||
                !user.agree ||
                !user.validation.email ||
                !user.validation.firstName ||
                !user.validation.password
              }
              hideIconOnTablet
            />
          </>
        ) : (
          <>
            <Input
              pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
              value={user.email}
              onChange={(email) => setUser({ ...user, email })}
              name="email"
              label="Enter the same email when you purchased"
              placeholder="email@example.com"
              onValidate={onValidate}
            />
            {/* <Input
          value={user.password}
          onChange={(password) => setUser({ ...user, password })}
          type="password"
          name="password"
          label="Password"
          placeholder="Enter your password"
        /> */}
            <Button
              hasMinWidth={false}
              text="Send Confirmation Email"
              disabled={!user.email || !user.validation.email}
              onClick={handleSignUp}
              icon="vg-mail"
              hideIconOnTablet
            />
            {!user.finalRegistration && (
              <p className="font font-normal text-tiny text-ui-gray-plus-4 text-center lg:px-8 px-0">
                Note: You can not create an account without purchasing any of
                our product!
              </p>
            )}
          </>
        )}
      </Auth>
    </>
  );
}

CreateAccountPage.propTypes = {
  location: PropTypes.object,
};

export default CreateAccountPage;
